import moment from 'moment-timezone';
import momentFr from '../config/moment.fr';

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');

export function getAlterationsFromProblems(treeInverse, pricingInverse, { clothSlug, problems }) {
  if (!problems) return [];
  const alterations = [];
  Object.values(problems)
    .forEach((locations, index) => {
      if (typeof locations[0] === 'string') {
        locations.forEach((locationSlug) => {
          const location = treeInverse[`${clothSlug}_${locationSlug}`];
          alterations.push(pricingInverse[location.id]);
          if (problems[`${locationSlug}_multiple`]) {
            alterations[alterations.length - 1].quantity = problems[`${locationSlug}_multiple`][0].value;
          }
        });
      } else if (Object.keys(problems)[index] === 'adjustment_acce_multiple') {
        alterations.push(pricingInverse['9.acce-div-21']);
        alterations[alterations.length - 1].quantity = problems.adjustment_acce_multiple[0].value;
      }
    });
  return alterations;
}

export function getAlterationsForCloth(
  treeInverse, pricingInverse, { clothSlug, problems },
) {
  return getAlterationsFromProblems(treeInverse, pricingInverse, { clothSlug, problems });
}

export const getClothType = (piece, fabric, tree) => {
  if (tree.find(({ slug }) => slug === piece)?.hasNoTypes) return 'DEFAULT';
  if (!fabric) return 'SIMPLE';
  if (!['other', 'jeans'].includes(fabric)) return 'COMPLEX';
  return 'SIMPLE';
};

export const getClothName = (tree, clothSlug) => {
  const treeCloth = tree.find((cloth) => cloth.slug === clothSlug);
  return treeCloth?.name || '';
};

const getSlug = (cloth) => cloth === 'mail' ? 'ling' : cloth;

export const formatCloth = (tree, treeInverse, pricingInverse,
  {
    selectedCloth, selectedLining, selectedProblems, selectedFabric,
  },
  brand) => ({
  slug: getSlug(selectedCloth),
  name: getClothName(tree, selectedCloth),
  liningsNumber: selectedLining === 'haveLining' ? 1 : 0,
  fabric: selectedFabric,
  typeOfWork: 'alteration',
  clothType: getClothType(selectedCloth, selectedFabric, tree),
  brand: !!brand,
  items: getAlterationsForCloth(
    treeInverse, pricingInverse,
    {
      clothSlug: selectedCloth,
      problems: selectedProblems,
    },
  ).map((alteration) => {
    const type = getClothType(selectedCloth, selectedFabric, tree);
    return ({
      id: alteration.id,
      name: alteration.name,
      quantity: alteration.quantity || 1,
      price: alteration.prices[type],
      liningUnitPrice: alteration.liningPrices?.[type],
    });
  }),
});

export function formatAllDaySlots(selectedDate) {
  const slots = [];
  const { hours, minutes } = selectedDate.slotBlocks[0].slots[0];
  const begin = moment(selectedDate.date).set({
    hours, minutes, seconds: 0, milliseconds: 0,
  });

  const lastSlotBlock = selectedDate.slotBlocks[selectedDate.slotBlocks.length - 1];
  const slotsLength = lastSlotBlock.slots.length;
  const { hoursEnd, minutesEnd } = lastSlotBlock.slots[slotsLength - 1];
  const end = moment(selectedDate.date).set({
    hours: hoursEnd, minutes: minutesEnd, seconds: 0, milliseconds: 0,
  });

  const current = moment(begin);

  while (!current.isSameOrAfter(end, 'minutes')) {
    slots.push({
      begin: current.toDate(),
      end: moment(current).add(30, 'minutes').toDate(),
    });
    current.add(30, 'minutes');
  }

  return slots;
}

export function formatBlockSlots(date, slotBlock) {
  const slots = [];
  const { hours, minutes } = slotBlock.slots[0];
  const begin = moment(date).set({
    hours, minutes, seconds: 0, milliseconds: 0,
  });

  const slotsLength = slotBlock.slots.length - 1;
  const { hoursEnd, minutesEnd } = slotBlock.slots[slotsLength];
  const end = moment(date).set({
    hours: hoursEnd, minutes: minutesEnd, seconds: 0, milliseconds: 0,
  });

  const current = moment(begin);

  while (!current.isSameOrAfter(end, 'minutes')) {
    slots.push({
      begin: current.toDate(),
      end: moment(current).add(30, 'minutes').toDate(),
    });
    current.add(30, 'minutes');
  }

  return slots;
}

export function formatSlot(date, slot) {
  const { hours, minutes } = slot;
  const begin = moment(date).set({
    hours, minutes, seconds: 0, milliseconds: 0,
  }).toDate();

  const { hoursEnd, minutesEnd } = slot;
  const end = moment(date).set({
    hours: hoursEnd, minutes: minutesEnd, seconds: 0, milliseconds: 0,
  }).toDate();
  return { begin, end };
}

export function formatSlots(selectedDates) {
  const slots = [];
  Object.values(selectedDates).forEach((selectedDate) => {
    if (selectedDate.allDay.isActive) {
      slots.push(...formatAllDaySlots(selectedDate));
    } else {
      selectedDate.slotBlocks.forEach((slotBlock) => {
        if (slotBlock.isActive) {
          slots.push(...formatBlockSlots(selectedDate.date, slotBlock));
        } else {
          slotBlock.slots.filter((slot) => slot.isActive).forEach((slot) => {
            slots.push(formatSlot(selectedDate.date, slot));
          });
        }
      });
    }
  });
  return slots;
}

export function getRdv1ForNow() {
  return {
    begin: new Date(), end: new Date(),
  };
}
